import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
//import { useDispatch } from "react-redux";
import { _MapContext as MapContext } from "react-map-gl";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
//import './MeepGeocoder.scss';

const Geocoder = ({ mapObject, label, changeViewport }) => {
  //const dispatch = useDispatch();
  const geocoderContainerRef = useRef(null);
  //const [nameSave, changeViewport] = useState("name.json");
  //const [nameSave, geolocation] = useState("name.json");

  useEffect(() => {
    const geocoderObject = new MapboxGeocoder({
      accessToken: "pk.eyJ1IjoianNhbnRvc20iLCJhIjoiY2tremIxMzN4MTg2bTJybzR5c3N6bTVhdyJ9.hsEiYUhOE-U8t0iCUY1wGQ",
      mapboxgl: MapContext,
      marker: false,
      placeholder: label,
      language: "en-EN",
      getItemValue: (item) => {
        changeViewport(item.center);
        //geolocation(item.center);
        /*         changeViewport({
          longitude: item.center[0],
          latitude: item.center[1],
          zoom: 17,
          pitch: 0,
          bearing: 0,
        }); */
        return item.place_name;
      },
    });

    geocoderContainerRef.current.appendChild(geocoderObject.onAdd(mapObject.map));
    geocoderObject.on("clear", () => {
      changeViewport([])
      //geolocation([]);
    });
  }, []);

  return <div ref={geocoderContainerRef}></div>;
};

Geocoder.propTypes = {
  mapObject: PropTypes.any,
  changeViewport: PropTypes.func,
  geolocation: PropTypes.func,
  label: PropTypes.string,
};

export default Geocoder;
