import "./App.css";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../src/pages/login";
import Home from "../src/pages/home";
import Catalog from "../src/pages/imageCatalog";
import Dashboard from "../src/pages/dashboardResults";
import Projects from "../src/pages/projects";
import NewProject from "../src/pages/newProject";
import logo_Imageryst from "../src/img/logo-imageryst-07-17@2x.png";
import logo_Imageryst11 from "../src/img/logo-imageryst-11-1@2x.png";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
//import theme from "./theme/theme"
import PrivateRoute from "./pages/types";

function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: '#E33E7F'
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <PrivateRoute exact path="/home" component={Home}></PrivateRoute>
          <PrivateRoute exact path="/imageCatalog" component={Catalog}></PrivateRoute>
          <PrivateRoute exact path="/projects" component={Projects}></PrivateRoute>
          <PrivateRoute exact path="/dashboardResults" component={Dashboard}></PrivateRoute>
          <PrivateRoute exact path="/newProject" component={NewProject}></PrivateRoute>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
