import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Geocoder from "../geocoder";
import useStyles from "../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";

const SearchMap = ({ mapObject, changeViewport }) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const [openSearchPanel, setOpenSearchPanel] = useState(false);

  const handleClickSearch = () => {
    setOpenSearchPanel(!openSearchPanel);
  };

  return (
    <div className={classes.divBtn}>
      <Tooltip title={t('common.searchInput')}>
        <Button
          className={`btn-map-first ${classes.btn} ${openSearchPanel ? classes.btnOpen : classes.btnNoOpen}`}
          variant="contained"
          /*onClick={handleClickPanel}*/
          startIcon={<SearchIcon className={classes.btn_search} />}
          onClick={handleClickSearch}
        ></Button>
      </Tooltip>
      {openSearchPanel && (
        <div className={classes.containerSearch}>
          <Geocoder mapObject={mapObject} label="Search..." changeViewport={changeViewport} />
        </div>
      )}
    </div>
  );
};

SearchMap.propTypes = {
  mapObject: PropTypes.any,
  changeViewport: PropTypes.func,
};

export default SearchMap;
