
import { styled } from "@mui/material/styles";

import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { checkAoi } from "../../../utils/requests";
import { useState } from "react";
import CustomSnackbar from "../../../components/snackbar";

const SUBIMAGE_NAME_EQUIVALENCES: any = {
  "Sentinel-2": "Sentinel-2 (10m)",
  "PlanetScope": "PlanetScope (3m)",
  "LIDAR": "LIDAR (1m)",
  "DTM": "DTM (5m)",
  "DSM": "DSM (1m)",
  "VIIRS-VNP46A3-500M": "VIIRS-VNP46A3 (500m)",
  "PNOA": "PNOA Spain (0,25m)",
  "Google": "Google (0,25m)",
  "Mapbox": "Mapbox (0,25m)",
  "ArcGIS": "Esri (0,25m)",
  "Azure": "ponga Bing (0,25m)",
}

function Step2(props: any) {

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const { t } = useTranslation()

  const {
    activeStep,
    stepIndex,
    attributeListMaestros,
    objective,
    btnDrawActive,
    activeDraw,
    btnUploadActive,
    handleUploadFile,
    geocoderContainerRef,
    features,
    isNextStepBlocked,
    handleStep,
    inputImages,
    setInputImages
  } = props

  const classes = useStyles()

  const [snackbarProps, setSnackbarProps] = useState({ message: '', severity: '' })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const filterInputImages = (availableBaseImages: any) => {
    const newInputImages = inputImages.map((_o: any, index: number) => {
      const attr = attributeListMaestros.find((att: any) => att.id_algorithm_attribute === objective[index].attribute);
      if (attr && attr.base_image) {
        const baseImageDict = JSON.parse(attr.base_image);
        const baseImageKeys = Object.keys(baseImageDict).reduce((acc: any, key) => {
          const children = baseImageDict[key];
          const trueChildren = Object.entries(children).reduce((childAcc: any, [childKey, childValue]) => {
            if (childValue === "True" || childKey === "default") {
              childAcc[childKey] = SUBIMAGE_NAME_EQUIVALENCES[childKey];
            }
            return childAcc;
          }, {});
          if (Object.keys(trueChildren).length > 0) {
            acc[key] = trueChildren;
          }
          return acc;
        }, {});

        const filteredInputImages = Object.entries(baseImageKeys).reduce((objectiveAcc: any, [key, value]: any) => {
          if (key === "Digital Terrain Models") {
            const filtered = Object.entries(value).reduce((acc: any, [basemap, basemapValue]) => {
              if (availableBaseImages.includes(basemap.toLowerCase())) {
                acc = { ...acc, [basemap]: basemapValue }
              }
              return acc
            }, {})
            objectiveAcc = { ...objectiveAcc, [key]: filtered }
          }
          else {
            objectiveAcc = { ...objectiveAcc, [key]: value }
          }
          return objectiveAcc
        }, {})
        return filteredInputImages
      }
    })
    console.log(newInputImages)
    return newInputImages
  }

  const hasLidar = () => {
    const hasLidar = objective.reduce((acc:boolean, ob:any)=> {
      return acc || ob.selectImageType === "Digital Terrain Models" 
    }, false)
    return hasLidar
  }

  const handleNextStep = async (nextStep: number) => {
    await checkAoi(features).then(
      function (response) {
        if (response.status === 200) {
          const availableBaseImages = response.data.message
          const newInputImages = filterInputImages(availableBaseImages)
          setInputImages(newInputImages)
          handleStep(nextStep)();
        }
        else {
          setSnackbarProps({ message: response.data.message, severity: 'error' })
          setOpenSnackbar(true)
        }
      }
    ).catch(async function (err) {
      if (err.response.status == 404 && !hasLidar()) {
        const newInputImages = filterInputImages([])
        setInputImages(newInputImages)
        handleStep(nextStep)();
      }
      else {
        setSnackbarProps({ message: err.response.data.message, severity: 'error' })
        setOpenSnackbar(true)
        console.log(err);
      }
    });

  }


  return (
    <div className={activeStep === stepIndex ? classes.activeStep : classes.disabledStep}>
      <div className="containerStep2">
        <div>
          <ButtonCustom
            label={t('common.drawButton')}
            className={`${btnDrawActive ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
            variant="contained"
            handleClick={activeDraw}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.drawTooltip')}
          />
          <ButtonCustom
            label={t('common.uploadButton')}
            className={`${btnUploadActive ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
            variant="contained"
            //handleClick={handleOpenModalArea}
            disabled={activeStep !== stepIndex}
            tooltip={t('newProject.uploadTooltip')}
            content={
              <VisuallyHiddenInput type="file" accept=".kml, .geojson, .shp, .kmz, .dxf" onChange={handleUploadFile} />
            }
          />

        </div>
        {activeStep !== stepIndex && <div className="divDisable"></div>}
        <div ref={geocoderContainerRef}></div>
      </div>
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t('common.backButton')}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(0)}
            tooltip={t('common.backTooltip')}
          />
          <ButtonCustom
            label={t('common.continueButton')}
            className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
            variant="contained"
            handleClick={() => handleNextStep(2)}
            tooltip={t('common.continueTooltip', { extraText: t('common.continueImage') })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
      <CustomSnackbar
        snackbarProps={snackbarProps}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar} />
    </div>
  );
}

export default Step2
