import { Button, Tooltip } from "@mui/material";
import useStyles from "../../../theme/styles";
import "./style.scss";

function ButtonCustom(props: any) {
  const classes = useStyles();

  const { label, className, variant, handleClick, disabled = false, data = "", tooltip = "", startIcon = false, style = {}, content = "" } = props;

  return (
    !disabled ? (
      <Tooltip title={tooltip} placement="top-end">
        <Button
          variant={variant}
          className={`${className} ${classes.btnB} buttonImageryst`}
          onClick={handleClick}
          disabled={disabled}
          data-id={data}
          startIcon={startIcon}
          style={style}
          component="label"
        >
          {label}
          {content}
        </Button>
      </Tooltip>) : (
      <Button
        variant={variant}
        className={`${className} ${classes.btnB} buttonImageryst`}
        onClick={handleClick}
        disabled={disabled}
        data-id={data}
        startIcon={startIcon}
        style={style}
      >
        {label}
      </Button>
    )
  );
}

export default ButtonCustom;