import { TextField } from "@mui/material";
import useStyles from "../../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";

function SearchInputCustom(props) {

    const { icon, handleSearch, value, onChange } = props

    const {t} = useTranslation()

    const classes = useStyles();
    return (
        <>
            <TextField
                InputProps={{
                    endAdornment: icon
                }}
                size="small"
                className={classes.inputWhite}
                label={t('common.searchInput')}
                variant="standard"
                color="secondary"
                value={value}
                onChange={onChange}
                onKeyDown={(e) => e.key === "Enter" ? handleSearch() : null}
            />
        </>
    );
}

export default SearchInputCustom;
