import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
  IconButton,
  Menu,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckboxCustom from "../ui/checkbox";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InvertColorsOutlinedIcon from "@mui/icons-material/InvertColorsOutlined";
import StyleCard from "../styleCard";
import useStyles from "../../theme/styles";
import "./style.scss";
import { FileCopyOutlined } from "@mui/icons-material";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useTranslation } from "react-i18next";

function MenuMap(props: any) {
  const {
    openPanel,
    listDataset,
    handleChangeCheckLayer,
    dataResultsMap,
    handleChangeSelect,
    optionSelectKPI,
    radiationMinMaxValue,
    altitudeMinMaxValue,
    handleDownloadDataset,
    openMenu,
    anchorEl,
    handleChangeTransparency,
    handleChangeTransparencyXyz,
    area
  } = props;
  const classes = useStyles();

  const { t } = useTranslation()

  const [value, setValue] = useState("0");
  const [xyzLayerActive, setXyzLayerActive] = useState<{ [id: string]: boolean }>({});

  const toggleXyzLayer = (id: string, checked: boolean | undefined = undefined) => {
    setXyzLayerActive((prevState) => {
      return {
        ...prevState,
        [id]: checked === undefined ? !prevState[id] : checked
      };
    });
  };

  const handleChangeCheckDataset = (e: React.ChangeEvent<HTMLInputElement>, id_project: string, id_dataset: string, name_attribute: string, transparency: number) => {
    const checked = e.target.checked
    toggleXyzLayer(id_dataset, checked)
    handleChangeCheckLayer(e, id_project, id_dataset, name_attribute, transparency)
  }

  const token = localStorage.getItem("token");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleCopyPassword = () => {
    if (token) {
      navigator.clipboard.writeText(token);
    }
  };

  const formatValue = (value: any) => {
    const v = parseFloat(value).toFixed(5).toString();
    const formated = v.replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
    return formated;
  };

  return (
    <>
      {openPanel && (
        <div className="panel_container">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              className={classes.tabs}
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#7024d1",
                },
              }}
            >
              <Tab label={t('dashboardResults.legendTab')} value="0" />
              <Tab label={t('dashboardResults.resultsTab')} value="1" />
              <Tab label={t('dashboardResults.sharingTab')} value="2" />
            </Tabs>
          </Box>
          {value === "0" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{ fontSize: 18 }}
                  color="text.secondary"
                  gutterBottom
                ></Typography>
                {Object.values(listDataset).map((pro: any) =>
                  pro.map(
                    (
                      {
                        name_asset,
                        name_attribute,
                        checked,
                        id_project,
                        project_name,
                        last_execution_date,
                        id_dataset,
                        state,
                        openDonwload,
                        transparency,
                        url_image
                      }: any,
                      index: number
                    ) => {
                      return (
                        state === "Completed" &&
                        <>
                          <Accordion className="accordionItem" key={`${index}_accordion`}>
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className={`accordionTitle ${classes.card_content}`}
                              sx={{
                                pointerEvents: "none",
                              }}
                              key={`${index}_accordion_summary`}
                            >
                              <CheckboxCustom
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleChangeCheckDataset(
                                    e,
                                    id_project,
                                    id_dataset,
                                    name_attribute,
                                    transparency,
                                  )
                                }
                                checked={checked}
                              />
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {`${name_asset} ${name_attribute}`}
                              </Typography>
                              <div className={classes.card_icon}>
                                <span onClick={(e: any) => { e.stopPropagation() }}>
                                  <Tooltip
                                    title={`${project_name} - ${last_execution_date}`}
                                    placement="top-end"
                                  >
                                    <InfoOutlinedIcon
                                      titleAccess={t('common.infoLayer')}
                                      sx={{
                                        pointerEvents: "auto",
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                                <span onClick={(e: any) => openMenu(e, id_dataset, id_project)}
                                  data-id={id_dataset}>
                                  <FileDownloadOutlinedIcon titleAccess={t('common.download')}
                                    sx={{
                                      pointerEvents: "auto",
                                    }} />
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={openDonwload}
                                    onClose={(e: any) => openMenu(e, id_dataset, id_project)}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    disableScrollLock={true}
                                    key={`${index}_menu`}
                                  >
                                    <Tooltip title={`${t('common.download')} .geojson`}>
                                      <MenuItem
                                        key={`${index}_geojson`}
                                        data-id={id_dataset}
                                        onClick={(
                                          e: any
                                        ) =>
                                          handleDownloadDataset(
                                            e,
                                            id_project,
                                            id_dataset,
                                            project_name,
                                            name_asset,
                                            name_attribute
                                          )
                                        }>GeoJSON</MenuItem>
                                    </Tooltip>
                                    <Tooltip title={`${t('common.download')} .kml`}>
                                      <MenuItem
                                        key={`${index}_kml`}
                                        onClick={(
                                          e: any
                                        ) =>
                                          handleDownloadDataset(
                                            e,
                                            id_project,
                                            id_dataset,
                                            project_name,
                                            name_asset,
                                            name_attribute
                                          )
                                        }
                                      >KML</MenuItem>
                                    </Tooltip>
                                    <Tooltip title={`${t('common.download')} .csv`}>
                                      <MenuItem
                                        key={`${index}_csv`}
                                        onClick={(
                                          e: any
                                        ) =>
                                          handleDownloadDataset(
                                            e,
                                            id_project,
                                            id_dataset,
                                            project_name,
                                            name_asset,
                                            name_attribute
                                          )
                                        }
                                      >CSV</MenuItem>
                                    </Tooltip>
                                    <Tooltip title={`${t('common.download')} .shp`}>
                                      <MenuItem
                                        key={`${index}_shapefile`}
                                        onClick={(
                                          e: any
                                        ) =>
                                          handleDownloadDataset(
                                            e,
                                            id_project,
                                            id_dataset,
                                            project_name,
                                            name_asset,
                                            name_attribute
                                          )
                                        }
                                      >Shapefile</MenuItem>
                                    </Tooltip>
                                    <Tooltip title={`${t('common.download')} .dxf`}>
                                      <MenuItem
                                        key={`${index}_dxf`}
                                        onClick={(
                                          e: any
                                        ) =>
                                          handleDownloadDataset(
                                            e,
                                            id_project,
                                            id_dataset,
                                            project_name,
                                            name_asset,
                                            name_attribute
                                          )
                                        }
                                      >DXF</MenuItem>
                                    </Tooltip>
                                  </Menu>
                                </span>
                                <InvertColorsOutlinedIcon
                                  titleAccess={t('common.symbologySettings')}
                                  //onClick={handleChangePanel("panelLegend1")}
                                  sx={{
                                    pointerEvents: "auto",
                                  }}
                                />
                                {url_image ? (
                                  <MapOutlinedIcon
                                    titleAccess={t('common.toggleXYZLayer')}
                                    sx={{
                                      pointerEvents: checked ? "auto" : "none",
                                    }}
                                    className={!checked ? "xyz-disabled" : xyzLayerActive[id_dataset] ? "xyz-active" : "xyz-inactive"}
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      if (checked) {
                                        handleChangeTransparencyXyz(
                                          e,
                                          id_project,
                                          id_dataset,
                                          url_image
                                        );
                                        toggleXyzLayer(id_dataset);
                                      }
                                    }}
                                  />
                                ) : (
                                  <MapOutlinedIcon
                                    titleAccess={t('common.toggleXYZLayer')}
                                    sx={{
                                      pointerEvents: "auto",
                                      color: "lightgrey",
                                    }}
                                  />
                                )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails key={`${index}_accordion_details`}>
                              <div>
                                <StyleCard
                                  type={name_attribute}
                                  radiationMinMaxValue={radiationMinMaxValue}
                                  altitudeMinMaxValue={altitudeMinMaxValue}
                                  handleChangeTransparency={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleChangeTransparency(
                                      e,
                                      id_project,
                                      id_dataset,
                                      name_attribute
                                    )
                                  }
                                  transparency={transparency}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    }
                  )
                )}
              </Box>
            </div>
          )}
          {value === "1" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 248 }}
                  className={`select_white ${classes.select}`}
                >
                  <InputLabel className="label_select">{t("dashboardResults.selectDataset")}</InputLabel>
                  <Select
                    value={optionSelectKPI}
                    name="useCase"
                    onChange={handleChangeSelect}
                  >
                    {Object.values(listDataset).map((pro: any) =>
                      pro.map(
                        ({
                          name_asset,
                          name_attribute,
                          id_dataset,
                          id_project,
                          checked,
                        }: any) => {
                          return (
                            checked && (
                              <MenuItem
                                value={`${id_dataset}:${id_project}`}
                              >{`${name_asset} ${name_attribute}`}</MenuItem>
                            )
                          );
                        }
                      )
                    )}
                  </Select>
                </FormControl>
                <div className={classes.divCardContent}>
                  <Card
                    sx={{ minWidth: "30%" }}
                    className={`${classes.card} ${classes.card50}`}
                  >
                    <CardContent className={classes.card_contentkpi}>
                      <Typography
                        sx={{ fontSize: 16 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        #{t('dashboardResults.resultsItems')}
                      </Typography>
                      <div className={classes.card_kpi}>
                        <Typography variant="h5" gutterBottom component="div">
                          {dataResultsMap.count && optionSelectKPI
                            ? dataResultsMap.count
                            : "--"}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {t('dashboardResults.resultsAverage')}
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.average
                          ? dataResultsMap.average.toFixed(2)
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Máx.
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.maximum
                          ? dataResultsMap.maximum
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Min.
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {optionSelectKPI && dataResultsMap.minimum
                          ? dataResultsMap.minimum
                          : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <Card sx={{ minWidth: "30%" }} className={classes.card}>
                  <CardContent className={classes.card_contentkpi}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Area (km2)
                    </Typography>
                    <div className={classes.card_kpi}>
                      <Typography variant="h5" gutterBottom component="div">
                        {area ? formatValue(area) : "--"}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Box>
            </div>
          )}
          {value === "2" && (
            <div role="tabpanel">
              <Box sx={{ p: 3 }}>
                <TextField
                  label="Token"
                  disabled
                  value={token}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleCopyPassword}>
                        <FileCopyOutlined />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      color: "black",

                      "-webkit-text-fill-color": "black",
                    },
                  }}
                />
              </Box>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default MenuMap;
