import { Checkbox } from "@mui/material";
import "./style.scss";

function CheckboxCustom(props: any) {
  const { checked, handleChange, value = "", disabled = false, style = false } = props;

  return <>{style ?
    <Checkbox onClick={(e) => handleChange(e)} checked={checked} value={value} disabled={disabled} style={style} /> :
    <Checkbox onClick={(e) => handleChange(e)} checked={checked} value={value} disabled={disabled} />}</>

}

export default CheckboxCustom;
