import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Imageryst8(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1351.4 1261.5" xmlSpace="preserve">
                <path fill="#fff" d="M534.2 239.8c-17.2-33.6-65.2-33.6-82.4 0L98.2 1046.9H276l197.7-492.8c8.6-20.3 27.2-20.3 35.7.1l197.7 492.7h180.4L534.2 239.8zM899.8 239.8c-17.2-33.6-65.2-33.6-82.4 0l-109 248.8 82 187.5 48.9-121.9c8.6-20.3 27.2-20.3 35.7.1l197.7 492.7h180.4L899.8 239.8z" />
                <path d="M534.2 239.8c-17.2-33.6-65.2-33.6-82.4 0L98.2 1046.9H276l197.7-492.8c8.6-20.3 27.2-20.3 35.7.1l197.7 492.7h180.4L534.2 239.8zM899.8 239.8c-17.2-33.6-65.2-33.6-82.4 0l-109 248.8 82 187.5 48.9-121.9c8.6-20.3 27.2-20.3 35.7.1l197.7 492.7h180.4L899.8 239.8z" />
            </svg>
        </SvgIcon>
    );
}