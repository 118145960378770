import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import useStyles from "../../theme/styles";
import { Slider } from "@mui/material";
import "./style.scss";
import { useTranslation } from "react-i18next";

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const COLOR_SCALE_RADIATION = [
  "rgb(59, 27, 0)",
  "rgb(93, 42, 0)",
  "rgb(171, 86, 3)",
  "rgb(198, 105, 5)",
  "rgb(237, 165, 33)",
  "rgb(250, 198, 53)",
  "rgb(255, 230, 70)",
];

const COLOR_SCALE_ASPECT = [
  "rgb(141, 90, 153)",
  "rgb(250, 232, 35)",
  "rgb(251, 22, 12)",
  "rgb(60, 234, 17)",
  "rgb(141, 90, 153)",
];
const VALUES_SCALE_ASPECT = [45, 135, 225, 315, 360];

const COLOR_SCALE_SLOPE = [
  "rgb(26, 150, 65)",
  "rgb(166, 217, 106)",
  "rgb(230, 255, 192)",
  "rgb(253, 174, 97)",
  "rgb(215, 25, 28)",
];
const VALUES_SCALE_SLOPE = [5, 15, 25, 50, 86];

const COLOR_SCALE_NDVI = [
  "rgb(251, 22, 12)",
  "rgb(239, 69, 17)",
  "rgb(239, 171, 25)",
  "rgb(26, 150, 65)",
  "rgb(21, 225, 72)",
  "rgb(124, 237, 96)",
];
const VALUES_SCALE_NDVI = [0.3, 0.5, 0.6, 0.7, 0.8, 1];

const COLOR_SCALE_ALTITUDE = [
  "rgb(0, 102, 51)",
  "rgb(0, 153, 51)",
  "rgb(0, 204, 102)",
  "rgb(153, 255, 102)",
  "rgb(255, 255, 102)",
  "rgb(255, 204, 102)",
  "rgb(255, 153, 51)",
  "rgb(204, 102, 0)",
  "rgb(153, 102, 51)",
  "rgb(102, 51, 0)",
];

const VALUES_SCALE_VEGETATION_HEALTH = [
  "-1 - -0.01",
  "-0.01 - 0.17",
  "0.17 - 0.33",
  "0.33 - 0.49",
  "0.49 - 0.66",
  "0.66 - 0.83",
  "0.83 - 1",
];
const COLOR_SCALE_VEGETATION_HEALTH = [
  "rgb(215, 25, 28)",
  "rgb(240, 124, 74)",
  "rgb(254, 201, 129)",
  "rgb(196, 230, 135)",
  "rgb(119, 195, 92)",
  "rgb(91, 174, 61)",
  "rgb(26, 150, 65)",
];

const VALUES_SCALE_HUMIDITY = ["-1.0 - -0.5", "-0.5 - -0.2", "-0.2 - 0", "0 - 0.15", "0.15 - 0.3", "0.3 - 0.5", "0.5 - 1"];

const COLOR_SCALE_HUMIDITY = [
  "rgb(35,145,36)",
  "rgb(154,204,151)",
  "rgb(210, 252, 205)",
  "rgb(213, 242, 255)",
  "rgb(32, 144, 251)",
  "rgb(11, 51, 236)",
  "rgb(5, 25, 135)",
];

const VALUES_SCALE_WATER_BODIES = ["-1.0 - -0.3", "-0.3 - 0", "0 - 0.2", "0.2 - 0.5", "0.5 - 1.0"];

const COLOR_SCALE_WATER_BODIES = [
  "rgb(245, 105, 24)",
  "rgb(231, 215, 57)",
  "rgb(42, 185, 238)",
  "rgb(66, 73, 177)",
  "rgb(48, 18, 59)",
];

const VALUES_SCALE_NIGHT_TIME_LIGHTS = ["5", "10", "20", "30", "40", "50", "70", "75"]

const COLOR_SCALE_NIGHT_TIME_LIGHTS = [
  "rgb(0, 0, 0)",
  "rgb(36, 31, 0)",
  "rgb(73, 62, 0)",
  "rgb(109, 93, 0)",
  "rgb(146, 124, 0)",
  "rgb(182, 155, 0)",
  "rgb(219, 186, 0)",
  "rgb(255, 213, 0)",
];

const StyleCard = ({ type, radiationMinMaxValue, altitudeMinMaxValue, handleChangeTransparency, transparency }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const formatValue = (value) => {
    const v = parseInt(value).toString();
    const formated = v.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formated;
  };

  const VALUES_SCALE_RADIATION = [
    radiationMinMaxValue[0],
    ((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * 1 + radiationMinMaxValue[0],
    ((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * 2 + radiationMinMaxValue[0],
    ((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * 3 + radiationMinMaxValue[0],
    ((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * 4 + radiationMinMaxValue[0],
    ((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * 5 + radiationMinMaxValue[0],
    radiationMinMaxValue[1],
  ];

  const VALUES_SCALE_ALTITUDE = [
    altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 1 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 2 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 3 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 4 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 5 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 6 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 7 + altitudeMinMaxValue[0],
    ((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * 8 + altitudeMinMaxValue[0],
    altitudeMinMaxValue[1],
  ];

  return (
    <>
      {type === "Radiation" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.radiationUnits')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_RADIATION.map((element, index) => {
              return (
                <Tooltip title={`${formatValue(VALUES_SCALE_RADIATION[index])} ${t('common.radiationUnits')}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {type === "Aspect" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.degrees', { degreeRange: '(0-360º)' })}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_ASPECT.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_ASPECT[index]} ${t('common.degrees', { degreeRange: '(0-360º)' })}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {(type === "Slope" || type === "Slope (Pitch)") && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.degrees', { degreeRange: '(0-90º)' })}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_SLOPE.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_SLOPE[index]} ${t('common.degrees', { degreeRange: '(0-90º)' })}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {type === "NDVI" && (
        <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
          {COLOR_SCALE_NDVI.map((element, index) => {
            return (
              <Tooltip title={`${VALUES_SCALE_NDVI[index]}`} placement="top-end">
                <div className={classes.legend} style={{ backgroundColor: element }}></div>
              </Tooltip>
            )
          })}
        </div>
      )}
      {(type === "Altitude" || type === "Height" || type === "Vegetation height") && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.metersAvobeSeaLevel')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_ALTITUDE.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_ALTITUDE[index]}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {type === "Vegetation health" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_VEGETATION_HEALTH.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_VEGETATION_HEALTH[index]}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {(type === "Watersheds" || type === "Burned areas") && (
        <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
          <div
            className={classes.legend}
            style={{ backgroundColor: "rgb(112, 39, 209)", border: "2px solid rgb(112, 39, 209)" }}
          ></div>
        </div>
      )}
      {type === "Solar Panels" && (
        <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
          <div
            className={classes.legend}
            style={{ backgroundColor: "rgb(112, 39, 209)", border: "2px solid rgb(112, 39, 209)" }}
          ></div>
        </div>
      )}
      {(type === "Solar Trackers Plants" ||
        type === "Solar Panels Plants" ||
        type === "Roads (Solar Plants)" ||
        type === "Powerblocks") && (
          <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
            <div
              className={classes.legend}
              style={{ backgroundColor: "rgb(112, 39, 209)", border: "3px solid rgb(112, 39, 209)" }}
            ></div>
          </div>
        )}
      {type === "Wind Turbines" && (
        <div className="layout" style={{ display: "table" }}>
          <div
            className={classes.legend}
            style={{
              backgroundColor: "rgb(112, 39, 209)",
              border: "2px solid rgb(112, 39, 209)",
              borderRadius: "15px",
              width: "15px",
              height: "15px",
            }}
          ></div>
        </div>
      )}
      {type === "Humidity" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_HUMIDITY.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_HUMIDITY[index]}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {type === "Water bodies" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_WATER_BODIES.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_WATER_BODIES[index]}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
      {type === "Night time lights" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
            {COLOR_SCALE_NIGHT_TIME_LIGHTS.map((element, index) => {
              return (
                <Tooltip title={`${VALUES_SCALE_NIGHT_TIME_LIGHTS[index]}`} placement="top-end">
                  <div className={classes.legend} style={{ backgroundColor: element }}></div>
                </Tooltip>
              )
            })}

          </div>
        </div>
      )}
      <br />
      <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.opacity')}</p>
      <Slider
        style={{ width: "90%" }}
        size="small"
        valueLabelDisplay="auto"
        value={transparency}
        onChange={handleChangeTransparency}
        marks={[
          {
            value: 0,
            label: "0%",
          },
          {
            value: 100,
            label: "100%",
          },
        ]}
      />
    </>
  );
};

export default StyleCard;
