import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Imageryst6(props: SvgIconProps) {
	return (
		<SvgIcon {...props}><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 3379.1 615.2" xmlSpace="preserve">
			<path d="M-12.2-11.2h3403.5v637.7H-12.2z" />
			<path fill="#fff" d="M2147.5 338.4v79.9h44.2v-79.9c-7 1.3-14.3 2-22.1 2-7.8 0-15.1-.7-22.1-2zM2222.3 196.9V227c0 11.1-1.8 20.3-5.5 27.5-3.6 7.3-8.1 13-13.5 17.2-5.4 4.2-11.1 7.2-17.3 8.8-6.2 1.6-11.7 2.4-16.5 2.4-4.8 0-10.3-.8-16.5-2.4-6.2-1.6-11.9-4.5-17.3-8.8-5.3-4.2-9.8-10-13.5-17.2-3.6-7.3-5.5-16.4-5.5-27.5v-30.1h-42.4v30.3c0 14.1 2.3 27 6.8 38.5s11 21.3 19.2 29.4c8.3 8.1 18.3 14.3 30 18.8 11.7 4.4 24.7 6.7 39.1 6.7 14.5 0 27.7-2.2 39.4-6.7 11.7-4.4 21.7-10.7 30-18.8 8.3-8.1 14.6-17.9 19.1-29.4 4.4-11.5 6.7-24.3 6.7-38.5v-30.3h-42.3zM1700.8 196.7v.1c-10.9.3-20 1.9-27 5.1-7.7 3.5-13.7 8.2-18.2 14.2s-7.5 12.9-9.2 20.7c-1.7 7.8-2.5 16.2-2.5 25.1V353.2c0 9 .8 17.3 2.5 25.1 1.7 7.8 4.7 14.7 9.2 20.7 4.5 6 10.5 10.7 18.2 14.2 7 3.2 16.1 4.8 27 5.1l110.5.1V380h-99c-9.6-.2-15.9-2.8-18.8-8-2.9-5.4-4.4-11-4.4-19.2v-89.3c0-8.2 1.5-15 4.4-20.3 2.8-5.2 9.1-7.8 18.8-8h99v-38.6h-110.5z" />
			<path fill="#fff" d="M1706.2 289.1h105.2v39.1h-105.2zM1235.3 201.1c-4.7-9.2-17.7-9.2-22.4 0L1149.3 346c18.7-7.9 46.8-7.4 46.8-7.4h1.9l20.8-51.9c2.3-5.5 7.4-5.5 9.7 0l27.4 68.4h-53.6s-44.6-.6-58.9 15.4c-10.5 9.5-16.5 22-19.3 34-1.4 5.7-2 11.4-2 16.5h42.5c0-.7-.1-1.3-.1-2 0-26.3 21.5-26.3 31.2-26.3h75.3l11.4 28.3h49.1l-96.2-219.9zM883.4 201c-4.7-9.2-17.8-9.2-22.5 0l-96.4 220H813l53.9-134.3c2.3-5.5 7.4-5.5 9.7 0L930.5 421h49.2l-96.3-220zM983 201c-4.7-9.2-17.8-9.2-22.5 0l-29.7 67.8 22.4 51.1 13.3-33.2c2.3-5.5 7.4-5.5 9.7 0l53.9 134.3h49.2L983 201zM1575.6 297.1c-3.5 0-6.5 2.4-7.2 5.8-6.5 28.2-18.6 46.5-18.6 46.5l-.1.1c-1.1 1.8-2.1 3.6-3.4 5.3-4.3 5.9-9.4 10.9-15.3 15.1-5.9 4.2-12.3 7.5-19.4 9.8-7.1 2.3-14.4 3.5-22.1 3.5-10.1 0-19.5-2.1-28.3-6.2s-16.5-9.6-23-16.5c-6.6-6.9-11.8-14.8-15.6-23.9-3.8-9.1-5.8-18.7-5.8-28.8 0-9.9 1.9-19.4 5.8-28.6 3.8-9.2 9-17.2 15.6-24.1 6.6-6.9 14.2-12.4 23-16.5s18.2-6.2 28.3-6.2c12.1 0 23.4 3.2 33.8 9.7 5.3 3.3 10 6.9 14.3 11l.1-.3 28.7-30.4c-6.6-5.9-13.8-11.2-21.9-15.7-16.7-9.3-35-13.9-55-13.9-15.8 0-30.6 3-44.5 9.1-13.9 6.1-26.1 14.3-36.6 24.7-10.5 10.4-18.7 22.6-24.8 36.7-6.1 14-9.2 28.9-9.2 44.7 0 15.8 3.1 30.6 9.2 44.5 6.1 13.9 14.4 26.1 24.8 36.5 10.5 10.4 22.7 18.6 36.6 24.7 13.9 6.1 28.8 9.1 44.5 9.1 14.3 0 28-2.4 41.1-7.3 13-4.8 24.5-11.6 34.5-20.2s18.2-18.8 24.5-30.7c6.4-11.9 10.1-24.8 11.4-38.8 1.7-15.3-10.1-28.7-25.4-28.7z" />
			<path fill="#fff" d="M1494.4 335.3h24.6c14.8 0 27.9-9.6 32.5-23.6 1.7-5.1 2.9-10 3.8-14.5h-49.1c-6.6 0-11.9 5.3-11.9 11.9v26.2zM721.7 255.3v-58.4h-42.4v89.6h12.5c16.7-.2 29.9-14.5 29.9-31.2zM691.4 307.8h-12.1v110.5h42.4V298.9c-8.7 5.6-19.1 8.9-30.3 8.9zM2480.1 328.4c-2.9-6.8-6.8-12.4-11.5-17s-10.2-8.4-16.2-11.3c-6.1-2.9-12.2-5.3-18.3-7-6.2-1.8-12.1-3.1-17.9-4.1-5.8-1-10.8-2-15-2.9-5.7-1.4-11.5-2.7-17.6-4.1-6.1-1.4-11.6-3-16.7-5s-9.2-4.4-12.4-7.3c-1.6-1.4-2.7-3-3.5-4.7-1.7.2-3.4.3-5.1.3h-38.4c.4 6.7 1.8 12.6 4.2 17.8 2.9 6.4 6.8 11.8 11.7 16.4 4.8 4.6 10.3 8.4 16.4 11.4 6.1 3 12.2 5.5 18.5 7.3 6.3 1.9 12.3 3.3 18.2 4.4 5.9 1.1 11 2 15.5 2.8 6.7 1.2 13.1 2.4 19.2 3.8 6.2 1.4 11.5 3 16.1 5 4.5 2 8.2 4.5 10.9 7.5 2.7 3 4.1 6.7 4.1 11 0 3.3-.9 6.7-2.7 10.1-1.8 3.4-4.5 6.5-8.2 9.1-3.6 2.6-8.1 4.8-13.3 6.4-5.3 1.7-11.1 2.3-18 2.3h-86.8v37.9h87.2c12.5 0 23.9-1.4 34.2-4.1 10.3-2.7 19.2-6.8 26.7-12.2 7.5-5.4 13.2-12.2 17.3-20.4 4-8.2 6.1-17.9 6.1-29-.3-9.5-1.8-17.6-4.7-24.4zM2380.8 208.9v-11.7c-5.9.4-12 1.2-18.2 2.4-10 2-19.1 5.3-27.3 10.1s-14.9 11.3-20.1 19.6c-2.7 4.3-4.7 9.1-6 14.4h36.7c19.2.1 34.9-15.6 34.9-34.8zM2471.5 196.7h-69.3v12.1c0 9.3-2.3 18-6.3 25.8h75.6v-37.9zM1854.1 196.1v39.7h81.4c13.3-4.9 22.9-19.9 22.9-34.9v-4.6c-1.8-.1-3.7-.2-5.6-.2h-98.7zM2010.2 329.5c7.1-7 12.4-15.1 16.1-24.4 3.6-9.3 5.5-19.4 5.5-30.3 0-10.9-2.1-21.2-6.2-30.8-4.1-9.6-9.7-17.9-16.8-25-7.1-7.1-15.4-12.7-25-16.8-1.3-.5-2.5-1-3.8-1.5v.1c0 14-5.1 26.7-13.6 36.6 4.9 2.4 9 5.5 12.4 9.2 3.4 3.8 6.1 8.2 7.9 13.2 1.8 5 2.7 10 2.7 15s-.7 9.9-2.1 14.5c-1.4 4.6-3.6 8.8-6.5 12.4-2.9 3.6-6.7 6.5-11.4 8.6-4.7 2.1-10.1 3.2-16.4 3.2h-34.2v38.2h.1c4.6 82 102.9 66 102.9 66v-42.1s-45.6 5.9-54.5-25.7c5.7-1 11.1-2.3 16.1-4.2 10.7-3.7 19.7-9.2 26.8-16.2zM1896.6 257.2h-42.5v160.7h42.5V313.6zM2635.3 271.4h-16.7c-1.5 0-3 .1-4.4.3-12.9 2-22.1 13.8-22.1 26.9v121.9h43.2V271.4zM2592.1 257.7c7.7-4.9 16.8-7.7 26.5-7.7h16.7v-17.1h64.5v-38.3h-172.5v38.3h64.8v24.8z" />
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2147.5 338.4v79.9h44.2v-79.9c-7 1.3-14.3 2-22.1 2-7.8 0-15.1-.7-22.1-2z" fill="url(#SVGID_1_)" />
			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2222.3 196.9V227c0 11.1-1.8 20.3-5.5 27.5-3.6 7.3-8.1 13-13.5 17.2-5.4 4.2-11.1 7.2-17.3 8.8-6.2 1.6-11.7 2.4-16.5 2.4-4.8 0-10.3-.8-16.5-2.4-6.2-1.6-11.9-4.5-17.3-8.8-5.3-4.2-9.8-10-13.5-17.2-3.6-7.3-5.5-16.4-5.5-27.5v-30.1h-42.4v30.3c0 14.1 2.3 27 6.8 38.5s11 21.3 19.2 29.4c8.3 8.1 18.3 14.3 30 18.8 11.7 4.4 24.7 6.7 39.1 6.7 14.5 0 27.7-2.2 39.4-6.7 11.7-4.4 21.7-10.7 30-18.8 8.3-8.1 14.6-17.9 19.1-29.4 4.4-11.5 6.7-24.3 6.7-38.5v-30.3h-42.3z" fill="url(#SVGID_2_)" />
			<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M1700.8 196.7v.1c-10.9.3-20 1.9-27 5.1-7.7 3.5-13.7 8.2-18.2 14.2s-7.5 12.9-9.2 20.7c-1.7 7.8-2.5 16.2-2.5 25.1V353.2c0 9 .8 17.3 2.5 25.1 1.7 7.8 4.7 14.7 9.2 20.7 4.5 6 10.5 10.7 18.2 14.2 7 3.2 16.1 4.8 27 5.1l110.5.1V380h-99c-9.6-.2-15.9-2.8-18.8-8-2.9-5.4-4.4-11-4.4-19.2v-89.3c0-8.2 1.5-15 4.4-20.3 2.8-5.2 9.1-7.8 18.8-8h99v-38.6h-110.5z" fill="url(#SVGID_3_)" />
			<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path fill="url(#SVGID_4_)" d="M1706.2 289.1h105.2v39.1h-105.2z" />
			<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M1235.3 201.1c-4.7-9.2-17.7-9.2-22.4 0L1149.3 346c18.7-7.9 46.8-7.4 46.8-7.4h1.9l20.8-51.9c2.3-5.5 7.4-5.5 9.7 0l27.4 68.4h-53.6s-44.6-.6-58.9 15.4c-10.5 9.5-16.5 22-19.3 34-1.4 5.7-2 11.4-2 16.5h42.5c0-.7-.1-1.3-.1-2 0-26.3 21.5-26.3 31.2-26.3h75.3l11.4 28.3h49.1l-96.2-219.9z" fill="url(#SVGID_5_)" />
			<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M883.4 201c-4.7-9.2-17.8-9.2-22.5 0l-96.4 220H813l53.9-134.3c2.3-5.5 7.4-5.5 9.7 0L930.5 421h49.2l-96.3-220z" fill="url(#SVGID_6_)" />
			<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M983 201c-4.7-9.2-17.8-9.2-22.5 0l-29.7 67.8 22.4 51.1 13.3-33.2c2.3-5.5 7.4-5.5 9.7 0l53.9 134.3h49.2L983 201z" fill="url(#SVGID_7_)" />
			<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M1575.6 297.1c-3.5 0-6.5 2.4-7.2 5.8-6.5 28.2-18.6 46.5-18.6 46.5l-.1.1c-1.1 1.8-2.1 3.6-3.4 5.3-4.3 5.9-9.4 10.9-15.3 15.1-5.9 4.2-12.3 7.5-19.4 9.8-7.1 2.3-14.4 3.5-22.1 3.5-10.1 0-19.5-2.1-28.3-6.2s-16.5-9.6-23-16.5c-6.6-6.9-11.8-14.8-15.6-23.9-3.8-9.1-5.8-18.7-5.8-28.8 0-9.9 1.9-19.4 5.8-28.6 3.8-9.2 9-17.2 15.6-24.1 6.6-6.9 14.2-12.4 23-16.5s18.2-6.2 28.3-6.2c12.1 0 23.4 3.2 33.8 9.7 5.3 3.3 10 6.9 14.3 11l.1-.3 28.7-30.4c-6.6-5.9-13.8-11.2-21.9-15.7-16.7-9.3-35-13.9-55-13.9-15.8 0-30.6 3-44.5 9.1-13.9 6.1-26.1 14.3-36.6 24.7-10.5 10.4-18.7 22.6-24.8 36.7-6.1 14-9.2 28.9-9.2 44.7 0 15.8 3.1 30.6 9.2 44.5 6.1 13.9 14.4 26.1 24.8 36.5 10.5 10.4 22.7 18.6 36.6 24.7 13.9 6.1 28.8 9.1 44.5 9.1 14.3 0 28-2.4 41.1-7.3 13-4.8 24.5-11.6 34.5-20.2s18.2-18.8 24.5-30.7c6.4-11.9 10.1-24.8 11.4-38.8 1.7-15.3-10.1-28.7-25.4-28.7z" fill="url(#SVGID_8_)" />
			<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M1494.4 335.3h24.6c14.8 0 27.9-9.6 32.5-23.6 1.7-5.1 2.9-10 3.8-14.5h-49.1c-6.6 0-11.9 5.3-11.9 11.9v26.2z" fill="url(#SVGID_9_)" />
			<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M721.7 255.3v-58.4h-42.4v89.6h12.5c16.7-.2 29.9-14.5 29.9-31.2z" fill="url(#SVGID_10_)" />
			<linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M691.4 307.8h-12.1v110.5h42.4V298.9c-8.7 5.6-19.1 8.9-30.3 8.9z" fill="url(#SVGID_11_)" />
			<linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2480.1 328.4c-2.9-6.8-6.8-12.4-11.5-17s-10.2-8.4-16.2-11.3c-6.1-2.9-12.2-5.3-18.3-7-6.2-1.8-12.1-3.1-17.9-4.1-5.8-1-10.8-2-15-2.9-5.7-1.4-11.5-2.7-17.6-4.1-6.1-1.4-11.6-3-16.7-5s-9.2-4.4-12.4-7.3c-1.6-1.4-2.7-3-3.5-4.7-1.7.2-3.4.3-5.1.3h-38.4c.4 6.7 1.8 12.6 4.2 17.8 2.9 6.4 6.8 11.8 11.7 16.4 4.8 4.6 10.3 8.4 16.4 11.4 6.1 3 12.2 5.5 18.5 7.3 6.3 1.9 12.3 3.3 18.2 4.4 5.9 1.1 11 2 15.5 2.8 6.7 1.2 13.1 2.4 19.2 3.8 6.2 1.4 11.5 3 16.1 5 4.5 2 8.2 4.5 10.9 7.5 2.7 3 4.1 6.7 4.1 11 0 3.3-.9 6.7-2.7 10.1-1.8 3.4-4.5 6.5-8.2 9.1-3.6 2.6-8.1 4.8-13.3 6.4-5.3 1.7-11.1 2.3-18 2.3h-86.8v37.9h87.2c12.5 0 23.9-1.4 34.2-4.1 10.3-2.7 19.2-6.8 26.7-12.2 7.5-5.4 13.2-12.2 17.3-20.4 4-8.2 6.1-17.9 6.1-29-.3-9.5-1.8-17.6-4.7-24.4z" fill="url(#SVGID_12_)" />
			<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2380.8 208.9v-11.7c-5.9.4-12 1.2-18.2 2.4-10 2-19.1 5.3-27.3 10.1s-14.9 11.3-20.1 19.6c-2.7 4.3-4.7 9.1-6 14.4h36.7c19.2.1 34.9-15.6 34.9-34.8z" fill="url(#SVGID_13_)" />
			<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2471.5 196.7h-69.3v12.1c0 9.3-2.3 18-6.3 25.8h75.6v-37.9z" fill="url(#SVGID_14_)" />
			<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M1854.1 196.1v39.7h81.4c13.3-4.9 22.9-19.9 22.9-34.9v-4.6c-1.8-.1-3.7-.2-5.6-.2h-98.7z" fill="url(#SVGID_15_)" />
			<linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2010.2 329.5c7.1-7 12.4-15.1 16.1-24.4 3.6-9.3 5.5-19.4 5.5-30.3 0-10.9-2.1-21.2-6.2-30.8-4.1-9.6-9.7-17.9-16.8-25-7.1-7.1-15.4-12.7-25-16.8-1.3-.5-2.5-1-3.8-1.5v.1c0 14-5.1 26.7-13.6 36.6 4.9 2.4 9 5.5 12.4 9.2 3.4 3.8 6.1 8.2 7.9 13.2 1.8 5 2.7 10 2.7 15s-.7 9.9-2.1 14.5c-1.4 4.6-3.6 8.8-6.5 12.4-2.9 3.6-6.7 6.5-11.4 8.6-4.7 2.1-10.1 3.2-16.4 3.2h-34.2v38.2h.1c4.6 82 102.9 66 102.9 66v-42.1s-45.6 5.9-54.5-25.7c5.7-1 11.1-2.3 16.1-4.2 10.7-3.7 19.7-9.2 26.8-16.2z" fill="url(#SVGID_16_)" />
			<linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path fill="url(#SVGID_17_)" d="M1896.6 257.2h-42.5v160.7h42.5V313.6z" />
			<linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2635.3 271.4h-16.7c-1.5 0-3 .1-4.4.3-12.9 2-22.1 13.8-22.1 26.9v121.9h43.2V271.4z" fill="url(#SVGID_18_)" />
			<linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="679.321" y1="307.603" x2="2699.791" y2="307.603">
				<stop offset="0" stopColor="#7024d1" />
				<stop offset=".968" stopColor="#268ef1" />
				<stop offset="1" stopColor="#2491f2" />
			</linearGradient>
			<path d="M2592.1 257.7c7.7-4.9 16.8-7.7 26.5-7.7h16.7v-17.1h64.5v-38.3h-172.5v38.3h64.8v24.8z" fill="url(#SVGID_19_)" />
		</svg>
		</SvgIcon>

	);
}