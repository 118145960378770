import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./style.scss";

function SwitchCustom(props) {
  const { checked, handleChange } = props;
  
  const CustomSwitch = styled(Switch)(() => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#7024d1",
      "&:hover": {
        backgroundColor: "#7024d169",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#7024d169",
    },
  }));

  return <CustomSwitch checked={checked} onChange={handleChange}/>;
}

export default SwitchCustom;
