import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header/header";
import "./style.scss";

function Home() {
  return (
    <div className="container">
      <Header valueTab={"home"} />
      <Footer />
    </div>
  );
}

export default Home;
