// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xyz-active {
  color: #7024D1;
}

.xyz-inactive {
  color: black;
}

.xyz-disabled {
  color: rgba(0, 0, 0, 0.3) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/menuMap/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEE;EACE,YAAA;AACJ;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".xyz-active {\n    color: #7024D1;\n  }\n\n  .xyz-inactive {\n    color: black;\n  }\n\n.xyz-disabled{\n  color: rgba(0, 0, 0, 0.3) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
