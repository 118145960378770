import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useStyles from "../../theme/styles";
import Row from "./row";
import { useTranslation } from "react-i18next";


function TableC(props: any) {
  const classes = useStyles();
  const {t} = useTranslation();

  const { rows, header, keys, handleChangeCheck, downloadActive, handleDownloadAoiClick, isSelected, showExtraParameters } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    setAnchorEl(null);
    const { value } = event.currentTarget.dataset
    if (value)
      handleDownloadAoiClick(value)
  };


  const getExtraParameters = (data: any) => {
    const extraParameters: any = []

    if (data.input_alg_parameters?.start_date) {
      const date = `${data.input_alg_parameters?.start_date}`.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      extraParameters.push({
        name: t('common.startDate'),
        value: date
      })
    }
    if (data.input_alg_parameters?.end_date) {
      const date = `${data.input_alg_parameters?.end_date}`.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      extraParameters.push({
        name: t('common.endDate'),
        value: date
      })
    }
    if (data.input_alg_parameters?.cloud_percentage) {
      extraParameters.push({
        name: t('newProject.cloudCoverageField'),
        value: data.input_alg_parameters?.cloud_percentage
      })
    }

    if (data.input_alg_parameters?.spatial_scale) {
      extraParameters.push({
        name: t('newProject.spatialScaleField'),
        value: data.input_alg_parameters?.spatial_scale
      })
    }
    if (data.input_alg_parameters?.minimum_segment_size) {
      extraParameters.push({
        name: t('common.minimumSegmentSize'),
        value: data.input_alg_parameters?.minimum_segment_size
      })
    }
    return extraParameters
  }

  return (
    <TableContainer component={Paper} className={classes.containerTable}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.headerTableC}>
        <TableHead>
          <TableRow>
            {showExtraParameters ?
              <TableCell align="left" width={"5%"} className={classes.headerTableC}>
              </TableCell> 
              : null
            }
            {header.map((head: string, index: number) => (
              <TableCell align="left" width={keys[0] === "checkbox" ? "5%" : "20%"} className={classes.headerTableC} key={index}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row: any, indx: number) => {
              const extraParameters = getExtraParameters(row)
              return (
                <Row
                  handleClick={handleClick}
                  handleClose={handleClose}
                  handleChangeCheck={handleChangeCheck}
                  open={open}
                  anchorEl={anchorEl}
                  downloadActive={downloadActive}
                  isSelected={isSelected}
                  keys={keys}
                  row={row}
                  indx={indx}
                  showExtraParameters={showExtraParameters}
                  extraParameters={extraParameters}
                />
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableC;
