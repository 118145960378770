import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@mui/material";
import LenghtIcon from "../../img/length.png";
import LenghtIcon_hover from "../../img/length_hover.png";
import AreaIcon from "../../img/area.png";
import AreaIcon_hover from "../../img/area_hover.png";
import useStyles from "../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";

const MeasurementMap = ({ activeTool, type, measurement, statePanel, setStatePanel }) => {
  const classes = useStyles();
  const [icon, setIcon] = useState(type === "area" ? AreaIcon : LenghtIcon);

  const {t} = useTranslation();

  const handleClickMeasurement = () => {
    setStatePanel(!statePanel);
    activeTool();
  };

  return (
    <div className={classes.divBtn}>
      <Tooltip title={type === "area" ? t('dashboardResults.areaTool') : t('dashboardResults.distanceTool')}>
        <Button
          className={`btn-map-first ${classes.btn} ${statePanel ? classes.btnOpen : classes.btnNoOpen}`}
          variant="contained"
          /*onClick={handleClickPanel}*/
          startIcon={
            <img
              width={20}
              src={icon}
              onMouseEnter={() => setIcon(type === "area" ? AreaIcon_hover : LenghtIcon_hover)}
              onMouseLeave={() => setIcon(type === "area" ? AreaIcon : LenghtIcon)}
            />
          }
          onClick={handleClickMeasurement}
        ></Button>
      </Tooltip>
      {statePanel && <div className={classes.containerMeasurement}>{measurement}</div>}
    </div>
  );
};

MeasurementMap.propTypes = {
  activeTool: PropTypes.func,
  type: PropTypes.string,
  measurement: PropTypes.string,
  statePanel: PropTypes.bool,
  setStatePanel: PropTypes.func,
};

export default MeasurementMap;
