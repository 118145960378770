import React from 'react';
import { CircularProgress, } from '@mui/material';


const LanguageSelector: React.FC = () => {

    return (
        <div className="containerLoader">
            <CircularProgress
                sx={{
                    color: "#7024D1",
                }}
                size={70}
            />
        </div>
    );
};

export default LanguageSelector;