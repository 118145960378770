import { useState, useEffect, useRef } from "react";
import useStyles from "../../theme/styles";
import Footer from "../../components/footer";
import Header from "../../components/header/header";
import logo_event from "../../img/eventoutlined-6@2x.png";
import logo_event_hover from "../../img/eventoutlined-5@2x.png";
import { MVTLayer, TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import { scaleThreshold, scaleLinear } from "d3-scale";
import { getProjectByUser, fetchKPISData, fetchData, getNumberPages } from "../../utils/requests";
import LateralMenu from "../../components/lateralMenu/lateralMenu";
import MenuMap from "../../components/menuMap/menuMap";
import Map from "../../components/map/map";
import { bbox as turfBbox } from "@turf/turf";
import { getDownload_dataset, getXyzFolder } from "../../utils/requests";
import "./style.scss";
import { useHistory } from "react-router-dom";
import fileDownload from 'js-file-download';
import { SendTimeExtensionTwoTone } from "@mui/icons-material";


const SERVER = process.env.REACT_APP_MARTIN;
const APPSERVER = process.env.REACT_APP_SERVER_API_DOTGIS;

const COLOR_SCALE_ASPECT = scaleThreshold()
  .domain([45, 135, 225, 315, 360]) //N, E, S, W, N
  .range([
    [141, 90, 153],
    [250, 232, 35],
    [251, 22, 12],
    [60, 234, 17],
    [141, 90, 153],
  ]);

const COLOR_SCALE_SLOPE = scaleThreshold()
  .domain([5, 15, 25, 50, 86])
  .range([
    [26, 150, 65],
    [166, 217, 106],
    [230, 255, 192],
    [253, 174, 97],
    [215, 25, 28],
  ]);

const COLOR_SCALE_NDVI = scaleThreshold()
  .domain([0.3, 0.5, 0.6, 0.7, 0.8, 1])
  .range([
    [251, 22, 12],
    [239, 69, 17],
    [239, 171, 25],
    [26, 150, 65],
    [21, 225, 72],
    [124, 237, 96],
  ]);

const COLOR_SCALE_NIGHT_TIME_LIGHTS = scaleThreshold()
  .domain([5, 10, 20, 30, 40, 50, 70, 75])
  .range([
    [0, 0, 0],
    [36, 31, 0],
    [73, 62, 0],
    [109, 93, 0],
    [146, 124, 0],
    [182, 155, 0],
    [219, 186, 0],
    [255, 213, 0],
  ])

const COLOR_SCALE_VEGETATION_HEALTH = scaleLinear()
  .domain([-1, -0.01, 0.17, 0.33, 0.49, 0.66, 0.83, 1])
  .range([
    [215, 25, 28],
    [240, 124, 74],
    [254, 201, 129],
    [196, 230, 135],
    [119, 195, 92],
    [91, 174, 61],
    [26, 150, 65],
  ]);

const COLOR_SCALE_HUMIDITY = scaleThreshold()
  .domain([-0.5, -0.2, 0, 0.15, 0.3, 0.5, 1])
  .range([
    [35, 145, 36],
    [154, 204, 151],
    [210, 252, 205],
    [213, 242, 255],
    [32, 144, 251],
    [11, 51, 236],
    [5, 25, 135],
  ]);

const COLOR_SCALE_WATER_BODIES = scaleThreshold()
  .domain([-0.3, 0, 0.2, 0.5, 1.0])
  .range([
    [245, 105, 24],
    [231, 215, 57],
    [42, 185, 238],
    [66, 73, 177],
    [48, 18, 59],
  ]);

const FILE_EXTENSION_TRANSLATIONS = {
  "shapefile": ".zip",
  "kml": ".kml",
  "geojson": ".geojson",
  "csv": ".csv",
  "dxf": ".dxf"
}

function Dashboard() {
  const classes = useStyles();

  const [dataResultsMap, setDataResultsMap] = useState({});
  const [openLoader, setOpenLoader] = useState(false);

  const [valueSearchDataset, setValueSearchDataset] = useState("");

  const [valueLeftPanel, setValueLeftPanel] = useState({});

  const lateralMenuRef = useRef(null)

  const initialListProject = [
    {
      name_project: "",
      id_project: 0,
      checked: false,
      content: "",
      creation_date: "",
      display: false,
      transparency: 100,
    },
  ];
  const [listProject, setListProject] = useState(initialListProject);

  const [listDataset, setListDataset] = useState([]);

  const [optionSelectKPI, setOptionSelectKPI] = useState("");

  const [initialMapBbox, setInitialMapBbox] = useState({ bbox: null, zoom: null });

  const [aoi, setAoi] = useState(null);

  const [minValueRadiation, setMinValueRadiation] = useState(0);
  const [maxValueRadiation, setMaxValueRadiation] = useState(1500000);

  const [area, setArea] = useState(0);

  const [minValueAltitude, setMinValueAltitude] = useState(0);
  const [maxValueAltitude, setMaxValueAltitude] = useState(1500000);

  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleChangeSelect = (e) => {
    const { value } = e.target;
    setOptionSelectKPI(value);
  };

  useEffect(() => {
    const getKpis = async () => {
      const projectDataset = optionSelectKPI.split(":");
      let endpoint = null;
      if (listDataset[parseFloat(projectDataset[1])]) {
        const datasetSelect = listDataset[parseFloat(projectDataset[1])].find(
          (p) => p.id_dataset === parseFloat(projectDataset[0]),
        );
        if (datasetSelect.name_attribute === "Radiation") {
          endpoint = "GET_KPIS_RADIATION";
        } else if (datasetSelect.name_attribute === "Aspect") {
          endpoint = "GET_KPIS_ASPECT";
        } else if (datasetSelect.name_attribute === "Slope (Pitch)") {
          endpoint = "GET_KPIS_SLOPE";
        } else if (datasetSelect.name_attribute === "NDVI") {
          endpoint = "GET_KPIS_RADIATION";
        } else if (datasetSelect.name_attribute === "Altitude") {
          endpoint = "GET_KPIS_ALTITUDE";
        } else if (datasetSelect.name_attribute === "Height") {
          endpoint = "GET_KPIS_HEIGHT";
        } else if (datasetSelect.name_attribute === "Vegetation health") {
          endpoint = "GET_KPIS_VEGETATION_HEALTH";
        } else if (datasetSelect.name_attribute === "Building Footprints") {
          endpoint = false;
        } else if (datasetSelect.name_attribute === "Powerblocks") {
          endpoint = "GET_KPIS_POWEBLOCKS";
        } else if (
          datasetSelect.name_attribute === "Roads (Solar Plants)" ||
          datasetSelect.name_attribute === "Rooftop Type"
        ) {
          endpoint = false;
        } else if (datasetSelect.name_attribute === "Solar Panels Plants") {
          endpoint = "GET_KPIS_SOLAR_PANELS_PLANTS";
        } else if (datasetSelect.name_attribute === "Solar Trackers Plants") {
          endpoint = "GET_KPIS_SOLAR_PANELS_PLANTS_TRACKERS";
        } else if (datasetSelect.name_attribute === "Wind Turbines") {
          endpoint = "GET_KPIS_WIND_TURBINES";
        } else if (datasetSelect.name_attribute === "Humidity") {
          endpoint = "GET_KPIS_HUMIDITY";
        } else if (datasetSelect.name_attribute === "Water bodies") {
          endpoint = "GET_KPIS_WATER_BODIES";
        } else if (datasetSelect.name_attribute === "Solar Panels") {
          endpoint = "GET_KPIS_SOLAR_PANELS";
        } else if (datasetSelect.name_attribute === "Segmentation") {
          endpoint = "GET_KPIS_SEGMENTATION";
        } else if (datasetSelect.name_attribute === "Vegetation height") {
          endpoint = "GET_KPIS_VEGETATION_HEIGHT";
        } else if (datasetSelect.name_attribute === "Monitoring") {
          endpoint = "GET_KPIS_MONITORING";
        } else if (datasetSelect.name_attribute === "Burned areas") {
          endpoint = "GET_KPIS_BURNED_AREAS";
        } else if (datasetSelect.name_attribute === "Night time lights") {
          endpoint = "GET_KPIS_NIGHT_TIME_LIGHTS";
        } else if (datasetSelect.name_attribute === "Contour Lines") {
          endpoint = "GET_KPIS_CONTOUR_LINES";
        }
      }
      if (endpoint) {
        await fetchKPISData(projectDataset[1], projectDataset[0], endpoint)
          .then(function (response) {
            if (response.status === 200) {
              setDataResultsMap(response.data);
            } else {
              console.log("SE HA PRODUCIDO UN ERROR");
            }
          })
          .catch(function (err) {
            // some error here
          });
      }
    };
    optionSelectKPI && getKpis();
  }, [optionSelectKPI]);

  useEffect(() => {
    Object.values(valueLeftPanel).map(({ title, content, date }, index) => {
      if (title.toUpperCase().includes(valueSearchDataset.toUpperCase())) {
        setValueLeftPanel((prevState) => {
          return {
            ...prevState,
            [index]: {
              title,
              content,
              date,
              display: true,
            },
          };
        });
      } else {
        setValueLeftPanel((prevState) => {
          return {
            ...prevState,
            [index]: {
              title,
              content,
              date,
              display: false,
            },
          };
        });
      }
    });
  }, [valueSearchDataset]);

  // eslint-disable-next-line no-undef
  const openPanelLeftParams = localStorage.getItem("openPanelLeft");

  const [openPanel, setOpenPanel] = useState(false);
  const [openPanelLeft, setOpenPanelLeft] = useState(true);
  const [icon, setIcon] = useState(logo_event);

  const handleClickLeftPanel = () => {
    setOpenPanelLeft(!openPanelLeft);
  };

  useEffect(() => {
    openPanel ? setIcon(logo_event_hover) : setIcon(logo_event);
  }, [openPanel]);

  const [layersArray, setLayersArray] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = (event, id_dataset, id_project) => {
    event.stopPropagation();
    let dataSetSelect = listDataset[id_project].find((p) => p.id_dataset === id_dataset);
    dataSetSelect.openDonwload = false;
    setListDataset(listDataset);
    setAnchorEl(null);
  };
  const openMenu = (event, id_dataset, id_project) => {
    event.stopPropagation();
    let dataSetSelect = listDataset[id_project].find((p) => p.id_dataset === id_dataset);
    dataSetSelect.openDonwload = !dataSetSelect.openDonwload;
    setListDataset(listDataset);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeTransparency = async (event, id_project, id_dataset, name_attribute) => {
    event.stopPropagation();

    listDataset[id_project].forEach((dataset) => {
      if (dataset.id_dataset === id_dataset) {
        dataset.transparency = event.target.value;
      }
    });

    const layerId = getMVTName(name_attribute, id_dataset, id_project);

    if (layerId !== "") {
      const layerNoSelect = layersArray.filter((layer) => layer.id !== layerId);

      setLayersArray(layerNoSelect);

      const layer = await getMVTLayer(name_attribute, id_project, id_dataset, event.target.value);

      const checkedLayers = []
      listDataset[id_project].forEach((dataset) => {
        if (dataset.id_dataset === id_dataset && dataset.checked) {
          checkedLayers.push(layer);
        }
      });
      setLayersArray((oldArray) => [...oldArray, ...checkedLayers]);
    }
  };

  const handleChangeTransparencyXyz = async (event, id_project, id_dataset) => {
    event.stopPropagation();

    const layerId = `xyz${id_dataset}_${id_project}`;

    if (layerId !== "") {
      let layerNoSelect = layersArray.filter((layer) => layer.id !== layerId);

      setLayersArray(layerNoSelect);

      if (!layersArray.some(layer => layer.id === layerId)) {
        let layer = await getXyzLayer(id_project, id_dataset);
        if (layer)
          setLayersArray((oldArray) => [layer, ...oldArray]);
      }
    }
  };

  const getMVTName = (name_attribute, id_dataset, id_project) => {
    let layerId = "";
    if (name_attribute === "Radiation") {
      layerId = `mvtRadiation_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Aspect") {
      layerId = `mvtAspect_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Slope (Pitch)") {
      layerId = `mvtSlope_${id_dataset}_${id_project}`;
    } else if (name_attribute === "NDVI") {
      layerId = "mvtNDVI";
    } else if (name_attribute === "Altitude") {
      layerId = `mvtAltitude_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Watersheds") {
      layerId = `mvtWatersheds${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Panels") {
      layerId = `mvtSolarPanels${id_dataset}_${id_project}`;
    } else if (name_attribute === "Building Footprints") {
      layerId = `mvtBuildingFootprints${id_dataset}_${id_project}`;
    } else if (name_attribute === "Height") {
      layerId = `mvtHeight${id_dataset}_${id_project}`;
    } else if (name_attribute === "Vegetation health") {
      layerId = `mvtVegetationHealth${id_dataset}_${id_project}`;
    } else if (name_attribute === "Powerblocks") {
      layerId = `mvtPowerblocks${id_dataset}_${id_project}`;
    } else if (name_attribute === "Roads (Solar Plants)") {
      layerId = `mvtRoads${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Panels Plants") {
      layerId = `mvtSolarPanelsPlants${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Trackers Plants") {
      layerId = `mvtSolarTrackersPlants${id_dataset}_${id_project}`;
    } else if (name_attribute === "Wind Turbines") {
      layerId = `mvtWindTurbines${id_dataset}_${id_project}`;
    } else if (name_attribute === "Humidity") {
      layerId = `mvtHumidity${id_dataset}_${id_project}`;
    } else if (name_attribute === "Water bodies") {
      layerId = `mvtWaterBodies${id_dataset}_${id_project}`;
    } else if (name_attribute === "Segmentation") {
      layerId = `mvtSegmentation${id_dataset}_${id_project}`;
    } else if (name_attribute === "Rooftop Type") {
      layerId = `mvtRooftopType${id_dataset}_${id_project}`;
    } else if (name_attribute === "Vegetation height") {
      layerId = `mvtVegetationHeight_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Monitoring") {
      layerId = `mvtMonitoring_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Burned areas") {
      layerId = `mvtBurnedareas${id_dataset}_${id_project}`;
    } else if (name_attribute === "Night time lights") {
      layerId = `mvtNightTimeLights_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Contour Lines") {
      layerId = `mvtContourLines_${id_dataset}_${id_project}`;
    }
    return layerId;
  };


  const getMVTLayer = async (name_attribute, id_project, id_dataset, transparency, optionalParameters) => {
    if (name_attribute === "Radiation") {
      const maxRadiation = optionalParameters?.maxValueRadiation ? optionalParameters.maxValueRadiation : maxValueRadiation
      const minRadiation = optionalParameters?.minValueRadiation ? optionalParameters.minValueRadiation : minValueRadiation

      const COLOR_SCALE_RADIATION = scaleThreshold()
        .domain([
          minRadiation,
          ((maxRadiation - minRadiation) / 6) * 1 + minRadiation,
          ((maxRadiation - minRadiation) / 6) * 2 + minRadiation,
          ((maxRadiation - minRadiation) / 6) * 3 + minRadiation,
          ((maxRadiation - minRadiation) / 6) * 4 + minRadiation,
          ((maxRadiation - minRadiation) / 6) * 5 + minRadiation,
          maxRadiation,
        ])
        .range([
          [59, 27, 0],
          [93, 42, 0],
          [171, 86, 3],
          [198, 105, 5],
          [237, 165, 33],
          [250, 198, 53],
          [255, 230, 70],
        ]);
      return new MVTLayer({
        id: `mvtRadiation_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_radiation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.radiation !== null && f.properties.radiation > 0) {
            return COLOR_SCALE_RADIATION(f.properties.radiation);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.radiation !== null && f.properties.radiation > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    }
    else if (name_attribute === "Aspect") {
      return new MVTLayer({
        id: `mvtAspect_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_aspect/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.aspect !== null && f.properties.aspect > 0) {
            return COLOR_SCALE_ASPECT(f.properties.aspect);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.aspect !== null && f.properties.aspect > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Slope (Pitch)") {
      return new MVTLayer({
        id: `mvtSlope_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_slope/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.slope !== null && f.properties.slope > 0) {
            return COLOR_SCALE_SLOPE(f.properties.slope);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.slope !== null && f.properties.slope > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "NDVI") {
      return new MVTLayer({
        id: "mvtNDVI",
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_ndvi/{z}/{x}/{y}.pbf`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndvi !== null && f.properties.ndvi > 0 && f.properties.ndvi <= 1) {
            return COLOR_SCALE_NDVI(f.properties.ndvi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndvi !== null && f.properties.ndvi > 0 && f.properties.ndvi <= 1) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Altitude") {

      const maxAltitude = optionalParameters?.maxValueAltitude ? optionalParameters.maxValueAltitude : maxValueAltitude
      const minAltitude = optionalParameters?.minValueAltitude ? optionalParameters.minValueAltitude : minValueAltitude

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain([
          minAltitude,
          ((maxAltitude - minAltitude) / 9) * 1 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 2 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 3 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 4 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 5 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 6 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 7 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 8 + minAltitude,
          maxAltitude,
        ])
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtAltitude_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_altitude/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.altitude !== null && f.properties.altitude > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.altitude);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.altitude !== null && f.properties.altitude > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Vegetation height") {
      const maxAltitude = optionalParameters?.maxValueAltitude ? optionalParameters.maxValueAltitude : maxValueAltitude
      const minAltitude = optionalParameters?.minValueAltitude ? optionalParameters.minValueAltitude : minValueAltitude

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain([
          minAltitude,
          ((maxAltitude - minAltitude) / 9) * 1 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 2 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 3 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 4 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 5 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 6 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 7 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 8 + minAltitude,
          maxAltitude,
        ])
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtVegetationHeight_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_vegetation_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.vegetation_height !== null && f.properties.vegetation_height > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.vegetation_height);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.vegetation_height !== null && f.properties.vegetation_height > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Watersheds") {
      return new MVTLayer({
        id: `mvtWatersheds${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_watersheds/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 0.5,
        getLineColor: [112, 39, 209],
        //lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Panels") {
      return new MVTLayer({
        id: `mvtSolarPanels${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209],
        getLineWidth: 0.1,
        getLineColor: [0, 0, 0],
      });
    } else if (name_attribute === "Building Footprints") {
      return new MVTLayer({
        id: `mvtBuildingFootprints${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_building_footprints/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Rooftop Type") {
      return new MVTLayer({
        id: `mvtRooftopType${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_building_rooftop_type/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Height") {
      const maxAltitude = optionalParameters?.maxValueAltitude ? optionalParameters.maxValueAltitude : maxValueAltitude
      const minAltitude = optionalParameters?.minValueAltitude ? optionalParameters.minValueAltitude : minValueAltitude

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain([
          minAltitude,
          ((maxAltitude - minAltitude) / 9) * 1 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 2 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 3 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 4 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 5 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 6 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 7 + minAltitude,
          ((maxAltitude - minAltitude) / 9) * 8 + minAltitude,
          maxAltitude,
        ])
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtHeight${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.height !== null && f.properties.height > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.height);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.height !== null && f.properties.height > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Vegetation health") {
      return new MVTLayer({
        id: `mvtVegetationHealth${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_vegetation_health/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndvi !== null) {
            return COLOR_SCALE_VEGETATION_HEALTH(f.properties.ndvi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndvi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Powerblocks") {
      return new MVTLayer({
        id: `mvtPowerblocks${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_powerblocks/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Roads (Solar Plants)") {
      return new MVTLayer({
        id: `mvtRoads${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants_roads/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Panels Plants") {
      return new MVTLayer({
        id: `mvtSolarPanelsPlants${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Trackers Plants") {
      return new MVTLayer({
        id: `mvtSolarTrackersPlants${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants_trackers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Wind Turbines") {
      return new MVTLayer({
        id: `mvtWindTurbines${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_wind_turbines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getPointRadius: 5,
        pointRadiusUnits: "meters",
        pointRadiusScale: 3,
        pointType: "circle",
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Humidity") {
      return new MVTLayer({
        id: `mvtHumidity${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_humidity/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndwi !== null) {
            return COLOR_SCALE_HUMIDITY(f.properties.ndwi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndwi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Water bodies") {
      return new MVTLayer({
        id: `mvtWaterBodies${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_water_bodies/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: 0.7,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndwi !== null) {
            return COLOR_SCALE_WATER_BODIES(f.properties.ndwi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndwi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Segmentation") {
      return new MVTLayer({
        id: `mvtSegmentation${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_segmentation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Burned areas") {
      return new MVTLayer({
        id: `mvtBurnedareas${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_burned_areas/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthMinPixels: 2,
      });
    } else if (name_attribute === "Monitoring") {
      return new MVTLayer({
        id: `mvtMonitoring_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_monitoring/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: 0.7,
        pickable: true,
        getLineWidth: 2,
        getFillColor: [0, 0, 0, 0],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Night time lights") {
      return new MVTLayer({
        id: `mvtNightTimeLights_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_night_time_lights/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: 0.7,
        pickable: true,
        getFillColor: (f) => {
          if (f.properties.luminosity !== null) {
            return COLOR_SCALE_NIGHT_TIME_LIGHTS(f.properties.luminosity);
          }
        },
      });
    } else if (name_attribute === "Contour Lines") {
      return new MVTLayer({
        id: `mvtContourLines_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_contour_lines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: 0.7,
        pickable: true,
        getLineWidth: 0.3,
        getFillColor: [0, 0, 0, 0],
        getLineColor: [112, 39, 209],
      });
    }

  }

  const fetchMVTLayer = async (name_attribute, id_project, id_dataset, transparency) => {
    let project = listProject.find((p) => p.id_project === id_project);
    let area = project.area;
    setArea(area);
    if (name_attribute === "Radiation") {
      const endpoint = "GET_KPIS_RADIATION";
      const optionalParameters = {
        maxValueRadiation: 1500000,
        minValueRadiation: 0
      }

      await fetchKPISData(id_project, id_dataset, endpoint)
        .then(function (response) {
          if (response.status === 200) {
            setMaxValueRadiation(response.data.maximum);
            setMinValueRadiation(response.data.minimum);
            optionalParameters.maxValueRadiation = response.data.maximum;
            optionalParameters.minValueRadiation = response.data.minimum;
          } else {
            console.log("SE HA PRODUCIDO UN ERROR");
            setMaxValueRadiation(1500000);
            setMinValueRadiation(0);
          }
        })
        .catch(function (err) {
          // some error here
        });

      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, optionalParameters)

    } else if (name_attribute === "Altitude") {
      const endpoint = "GET_KPIS_ALTITUDE";

      const optionalParameters = {
        maxValueAltitude: 2500,
        minValueAltitude: 0
      }

      await fetchKPISData(id_project, id_dataset, endpoint)
        .then(function (response) {
          if (response.status === 200) {
            setMaxValueAltitude(response.data.maximum);
            setMinValueAltitude(response.data.minimum);
            optionalParameters.maxValueAltitude = response.data.maximum;
            optionalParameters.minValueAltitude = response.data.minimum;
          } else {
            console.log("SE HA PRODUCIDO UN ERROR");
            setMaxValueAltitude(2500);
            setMinValueAltitude(0);
          }
        })
        .catch(function (err) {
          // some error here
        });

      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, optionalParameters)

    } else if (name_attribute === "Vegetation height") {
      const endpoint = "GET_KPIS_VEGETATION_HEIGHT";

      const optionalParameters = {
        maxValueAltitude: 2500,
        minValueAltitude: 0
      }

      await fetchKPISData(id_project, id_dataset, endpoint)
        .then(function (response) {
          if (response.status === 200) {
            setMaxValueAltitude(response.data.maximum);
            setMinValueAltitude(response.data.minimum);
            optionalParameters.maxValueAltitude = response.data.maximum;
            optionalParameters.minValueAltitude = response.data.minimum;
          } else {
            console.log("SE HA PRODUCIDO UN ERROR");
            setMaxValueAltitude(2500);
            setMinValueAltitude(0);
          }
        })
        .catch(function (err) {
          // some error here
        });

      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, optionalParameters)
    } else if (name_attribute === "Height") {
      const endpoint = "GET_KPIS_HEIGHT";

      const optionalParameters = {
        maxValueAltitude: 2500,
        minValueAltitude: 0
      }

      await fetchKPISData(id_project, id_dataset, endpoint)
        .then(function (response) {
          if (response.status === 200) {
            setMaxValueAltitude(response.data.maximum);
            setMinValueAltitude(response.data.minimum);
            optionalParameters.maxValueAltitude = response.data.maximum;
            optionalParameters.minValueAltitude = response.data.minimum;
          } else {
            console.log("SE HA PRODUCIDO UN ERROR");
            setMaxValueAltitude(2500);
            setMinValueAltitude(0);
          }
        })
        .catch(function (err) {
          // some error here
        });

      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, optionalParameters)
    } else {
      return getMVTLayer(name_attribute, id_project, id_dataset, transparency)
    }

  };

  const getXyzLayer = async (id_project, id_dataset) => {
    let project = listProject.find((p) => p.id_project === id_project);
    let layer = undefined;
    let area = project.area;
    setArea(area);

    const token = localStorage.getItem("token_access_imageryst");

    await getXyzFolder(id_project, id_dataset)
      .then(function (response) {
        let dir = response.data.tiles_dir;
        dir = (dir.substring(dir.lastIndexOf("/") + 1));
        layer = new TileLayer({
          id: `xyz${id_dataset}_${id_project}`,
          data: `${APPSERVER}tile_service/retrieve_tiles/${dir}/{z}/{x}/{y}.png`,
          loadOptions: {
            fetch: {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }
          },
          maxZoom: 17,
          minZoom: 11,
          pickable: true,
          opacity: 100,
          renderSubLayers: props => {
            const { boundingBox } = props.tile;
            return new BitmapLayer(props, {
              data: null,
              image: props.data,
              bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]]
            });
          },
        });
      }).catch((error) => {
        console.log(error)
      })
    return layer;
  };


  const handleChangeCheckLayer = async (event, id_project, id_dataset, name_attribute, transparency) => {
    event.stopPropagation();
    let dataSetSelect = listDataset[id_project].find((p) => p.id_dataset === id_dataset);
    dataSetSelect.checked = event.target.checked;
    setListDataset(listDataset);

    const xyzLayerId = `xyz${id_dataset}_${id_project}`;

    setOptionSelectKPI(`${id_dataset}:${id_project}`);
    if (event.target.checked) {
      const layer = await fetchMVTLayer(name_attribute, id_project, id_dataset, transparency);
      const xyzLayer = await getXyzLayer(id_project, id_dataset);

      if (layer !== undefined) {
        if (xyzLayer) {
          setLayersArray((oldArray) => [xyzLayer, ...oldArray, layer]);
        }
        else {
          setLayersArray((oldArray) => [...oldArray, layer]);
        }
      }
    } else {
      const layerId = getMVTName(name_attribute, id_dataset, id_project);

      if (layerId !== "") {
        let layerNoSelect = layersArray.filter((layer) => layer.id !== layerId && layer.id !== xyzLayerId);

        setLayersArray(layerNoSelect);
      }
      setDataResultsMap({});
    }
    if (listDataset[id_project])
      listDataset[id_project].forEach((dataset) => {
        if (dataset.id_dataset === id_dataset) {
          dataset.checked = event.target.checked;
        }
      });
  };

  const getProjectList = async () => {
    const projectName = searched ? searched : null
    await getNumberPages(projectName, 'Completed')
      .then(function (response) {
        if (response.status === 200) {
          setNumberPages(response.data.number_of_pages);
        }
      })
      .catch(function (err) {
        // some error here
        console.log("SE HA PRODUCIDO UN ERROR");
        setOpenLoader(false);
      });
    await getProjectByUser(projectName ? 1 : page, projectName, null, null, 'Completed')
      .then(function (response) {
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map((p) => {
            return (p = {
              ...p,
              checked: false,
              content: "",
              date: "",
              display: true,
              openDonwload: false,
              transparency: 100,
            });
          });

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] + "-" + x.creation_date.split("-")[0] + "-" + x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] + "-" + y.creation_date.split("-")[0] + "-" + y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck);
        } else {
          console.log("SE HA PRODUCIDO UN ERROR");
        }
      })
      .catch(function (err) {
        // some error here
        console.log("SE HA PRODUCIDO UN ERROR");
      });

    localStorage.removeItem("idProject");
    localStorage.removeItem("nameProject");
  };

  useEffect(() => {
    getProjectList();
  }, []);


  const cleanLayers = (id_project) => {
    //remove all layer visible
    const arrayListLayerRemove = [];
    listDataset[id_project]?.forEach((d) => {
      const layerId = getMVTName(d.name_attribute, d.id_dataset, d.id_project);

      arrayListLayerRemove.push(layerId);
    });
    const layerNoSelect = layersArray.filter((layer) => {
      arrayListLayerRemove.forEach((l) => {
        return layer.id !== l;
      });
    });

    setLayersArray(layerNoSelect);
    delete listDataset[id_project];
  }

  const handleChangeProject = async (event, id_project, index, name_project) => {
    const project = listProject.find((p) => p.id_project === id_project);
    const area = project.area;

    setAoi(project.aoi);

    setArea(area);

    cleanLayers(id_project);

    const listProjectArray = [...listProject];
    listProjectArray.map((project, key) => {
      if (key === index) {
        project.checked = event.target.checked;
      } else {
        project.checked = false;
        setDataResultsMap({});
      }
    });
    setListProject(listProjectArray);

    const id_user = localStorage.getItem("id_user");
    if (event.target.checked) {
      await fetchData(`${id_user}/${id_project}`, "GET_DATASET")
        .then(function (response) {
          if (response.status === 200) {
            const listDatasetCheck = Object.values(response.data.message).map((p) => {
              return (p = {
                ...p,
                checked: false,
                display: true,
                project_name: name_project,
                openDonwload: false,
                transparency: 100,
              });
            });

            if (response.data.message.length > 0) {
              let features = [];
              response.data.message.forEach((item) => {
                features = [...features, ...item.input_alg_parameters.mask.features];
              });
              try {
                const bbox = turfBbox({
                  type: "FeatureCollection",
                  features: features,
                });
                setInitialMapBbox({ bbox: bbox, zoom: listDatasetCheck[0]?.default_zoom });
              } catch (error) {
                console.log(error);
              }
            }

            setListDataset((prevState) => {
              return {
                [id_project]: listDatasetCheck,
              };
            });
            setOpenPanel(true);
          } else {
            console.log("SE HA PRODUCIDO UN ERROR");
          }
        })
        .catch(function (err) {
          // some error here
        });
    } else {
      cleanLayers(id_project);
    }
  };

  const handleClickPanel = () => {
    setOpenPanel(!openPanel);
  };

  const handleDownloadDataset = async (event, id_project, id_dataset, project_name, name_asset, name_attribute) => {
    event.stopPropagation();
    const typeExtension = event.currentTarget.innerText.toLowerCase();
    //setAnchorEl(null);
    const filename =
      project_name.replaceAll(" ", "_").toLowerCase() +
      name_attribute.replaceAll(" ", "_").toLowerCase() +
      name_asset.replaceAll(" ", "_").toLowerCase();
    await getDownload_dataset(id_project, id_dataset, typeExtension)
      .then(async function (response) {
        if (response.status === 200) {
          fileDownload(response.data, `${filename}${FILE_EXTENSION_TRANSLATIONS[typeExtension]}`)
        } else {
          console.log("SE HA PRODUCIDO UN ERROR");
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    // Scroll to the top of the component when content changes
    if (lateralMenuRef?.current)
      lateralMenuRef.current.scrollTo(0, 0);
  }, [listProject]);

  const handleChangePagination = async (event, value) => {
    setOpenLoader(true);
    setPage(value);
    await getProjectByUser(value, null, null, null, 'Completed')
      .then(function (response) {
        setOpenLoader(false);
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map((p) => {
            return (p = {
              ...p,
              checked: false,
              content: "",
              date: "",
              display: true,
              openDonwload: false,
              transparency: 100,
            });
          });

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] + "-" + x.creation_date.split("-")[0] + "-" + x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] + "-" + y.creation_date.split("-")[0] + "-" + y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck);
        } else {
          console.log("SE HA PRODUCIDO UN ERROR");
        }
      })
      .catch(function (err) {
        // some error here
        setOpenLoader(false);
        console.log("SE HA PRODUCIDO UN ERROR");
      });
  };

  const history = useHistory();
  const handleChangeSearchDataset = () => {
    history.push({
      pathname: '/dashboardResults',
      search: searched ? `?projectName=${searched}` : ''
    })
    getProjectList();
  };

  const queryParameters = new URLSearchParams(window.location.search)
  const projectNameFilter = queryParameters.get("projectName")
  const [searched, setSearched] = useState(projectNameFilter ? projectNameFilter : "")
  const requestSearch = (event) => {
    const searchedVal = event.target.value;
    setSearched(searchedVal);
  };

  return (
    <div className="container">
      <Header valueTab={"dashboardResults"} />
      <div className="container_map">
        <LateralMenu
          openPanelLeft={openPanelLeft}
          handleClickLeftPanel={handleClickLeftPanel}
          listProject={listProject}
          handleChangeProject={handleChangeProject}
          numberPages={numberPages}
          page={page}
          handleChangePagination={handleChangePagination}
          openLoader={openLoader}
          setOpenLoader={setOpenLoader}
          handleSearch={handleChangeSearchDataset}
          searchValue={searched}
          onChangeSearchValue={requestSearch}
          lateralMenuRef={lateralMenuRef}
        />
        <Map
          openPanel={openPanel}
          layersArray={layersArray}
          handleClickPanel={handleClickPanel}
          initialMapBbox={initialMapBbox}
          setLayersArray={setLayersArray}
          aoi={aoi}
        />
        <MenuMap
          openPanel={openPanel}
          listDataset={listDataset}
          handleChangeCheckLayer={handleChangeCheckLayer}
          dataResultsMap={dataResultsMap}
          handleChangeSelect={handleChangeSelect}
          optionSelectKPI={optionSelectKPI}
          radiationMinMaxValue={[minValueRadiation, maxValueRadiation]}
          altitudeMinMaxValue={[minValueAltitude, maxValueAltitude]}
          handleDownloadDataset={handleDownloadDataset}
          openMenu={openMenu}
          anchorEl={anchorEl}
          handleChangeTransparency={handleChangeTransparency}
          handleChangeTransparencyXyz={handleChangeTransparencyXyz}
          area={area}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
