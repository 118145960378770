import { Typography } from "@mui/material";
import "./style.scss";

function Footer() {
  return (
    <div className="footer">
      <Typography variant="body2" display="block" gutterBottom>
        Privacy policy | Terms & Conditions | Contact us: contact@imageryst.com
      </Typography>
    </div>
  );
}

export default Footer;
