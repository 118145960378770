import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import "./style.scss";

interface LanguageOption {
    code: string;
    name: string;
}

const languages: LanguageOption[] = [
    { code: 'en', name: 'ENG' },
    { code: 'es-ES', name: 'ESP' },
];

const LanguageSelector: React.FC = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
        i18n.language
    );

    const handleChange = (event: any, _child: React.ReactNode) => {
        const selectedLang = event.target.value;
        setSelectedLanguage(selectedLang);
        i18n.changeLanguage(selectedLang);
    };

    return (
        <div className='container-lang'>
            <FormControl variant="standard" size="small" sx={{ outline: 'none', minWidth:50, maxHeight: 40 }}>
                <Select
                    id="language-selector"
                    disableUnderline
                    value={selectedLanguage}
                    onChange={handleChange}
                    sx={{ 
                        paddingTop:'0.3rem',
                        fontSize: '0.75rem', 
                        height: '2.5rem', 
                        display: 'flex', 
                        alignItems: 'center',
                    }}
                    MenuProps={{
                        disableScrollLock: true,
                      }}
                >
                    {languages.map((language) => (
                        <MenuItem key={language.code} value={language.code}>
                            {language.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSelector;