// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiSwitch-switchBase.Mui-checked {
  color: #7024d1 !important;
}
.MuiSwitch-switchBase.Mui-checked:hover {
  background-color: rgba(112, 36, 209, 0.4117647059) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: rgba(112, 36, 209, 0.4117647059) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/switch/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AAAI;EACE,6DAAA;AAEN;;AACA;EACI,6DAAA;AAEJ","sourcesContent":[".MuiSwitch-switchBase.Mui-checked {\n    color: #7024d1 !important;\n    &:hover {\n      background-color: #7024d169 !important;\n    }\n  }\n.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {\n    background-color: #7024d169 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
