// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiCheckbox-root {
  color: #7024d1 !important;
  pointer-events: auto !important;
}
.MuiCheckbox-root.Mui-disabled {
  color: #c4c4c4 !important;
}
.MuiCheckbox-root.Mui-checked {
  color: #7024d1 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/checkbox/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,+BAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".MuiCheckbox-root{\n    color: #7024d1 !important;\n    pointer-events: auto !important;\n\n    &.Mui-disabled {\n        color: #c4c4c4 !important;\n    }\n\n    &.Mui-checked {\n        color: #7024d1 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
