// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-buffer-first {
  min-width: 32.76px !important;
  width: 32.76px !important;
  height: 33.33px !important;
  background-color: #ffffff !important;
  margin-top: 22px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/aoiBuffer/style.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,yBAAA;EACA,0BAAA;EACA,oCAAA;EACA,2BAAA;AACJ","sourcesContent":[".btn-buffer-first {\n    min-width: 32.76px !important;\n    width: 32.76px !important;\n    height: 33.33px !important;\n    background-color: #ffffff !important;\n    margin-top: 22px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
