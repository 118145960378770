import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

export const COOKIES_CONFIG = {
  startData: "start_date",
  token: "token",
  tokenCreation: "token_creation",
  tokenRefreshLifespan: "token_refresh_lifespan",
  tokenAccessLifespan: "token_access_lifespan",
  userData: "user_data",
};

const AUTH_FACTORY = () => ({
  auth: {
    userData: {},
    userHasAuthenticated: false,
  },
  user: {
    id_user: "",
    email: "",
    name: "",
    roles: [],
    username: "",
  },
  userDataResetPass: {
    id_user: "",
    oldPassword: "",
  },
});

const INITIAL_STATE = AUTH_FACTORY();

const logOut = () => {
  /*sessionStorage.removeItem(COOKIES_CONFIG.userData);
  sessionStorage.removeItem(COOKIES_CONFIG.token);*/

  Cookies.remove(COOKIES_CONFIG.tokenCreation);
  Cookies.remove(COOKIES_CONFIG.tokenRefreshLifespan);
  Cookies.remove(COOKIES_CONFIG.userData);

  return INITIAL_STATE;
};

const store = createContext(INITIAL_STATE);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "login":
        return { ...state, auth: { ...action.payload } };
      case "logout":
        return logOut();
      case "user":
        return { ...state, user: { ...action.payload } };
      case "user reset pass":
        return { ...state, userDataResetPass: { ...action.payload } };
      default:
        return state;
    }
  }, INITIAL_STATE);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider };
