/* eslint-disable no-undef */
import {

  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
} from "@mui/material";

import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";



function Step3(props: any) {

  const {
    activeStep,
    stepIndex,
    objective,
    setObjective,
    inputImages,
    isNextStepBlocked,
    handleStep
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  const handleGenericChangeSelect = (e: SelectChangeEvent, index: number) => {
    const { name, value } = e.target;

    const newObjective = objective.map((o: any, idx: number) => {
      if (index === idx) {
        return {
          ...o,
          [name]: value
        }
      }
      else {
        return o
      }
    });
    setObjective(newObjective);
  };

  return (
    <div className={`${activeStep === stepIndex ? classes.activeStep : classes.disabledStep}`}>
      {objective.map((o: any, objectiveIndex: number) => {
        return (<div key={`image-select-${objectiveIndex}`} className="containerStep3_select">
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 248 }}
            className="select_white select_width"
            disabled={activeStep !== stepIndex}
          >
            <InputLabel className="label_select">{t('newProject.selectImageType', { datasetNumber: objectiveIndex + 1 })}</InputLabel>
            <Select
              onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
              name="selectImageType"
              className={`selectImage ${classes.selectColor}`}
              value={o.selectImageType || ""}
            >
              {
                Object.keys(inputImages[objectiveIndex]).map((img: any) => {
                  return <MenuItem value={img}>{img}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 248 }}
            className="select_white select_width"
            disabled={activeStep !== stepIndex}
          >
            <InputLabel className="label_select">{t('newProject.selectSubimageType', { datasetNumber: objectiveIndex + 1 })}</InputLabel>
            <Select
              onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
              name="selectSubImageType"
              className={`selectSubImage ${classes.selectColor}`}
              value={o.selectSubImageType || ""}
            >
              {Object.entries(inputImages[objectiveIndex][objective[objectiveIndex].selectImageType] || {}).map(([imageKey, imageValue]: any) => {
                return <MenuItem value={imageKey}>{imageValue}</MenuItem>
              })}
            </Select>
          </FormControl>
          {/* <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 248 }}
            className="select_white select_width_s"
            disabled={activeStep !== stepIndex}
          >
            <InputLabel className="label_select">Model type</InputLabel>
            <Select
              onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
              name="selectModelType"
              className={`selectModelType ${classes.selectColor}`}
              value={o.selectModelType || ""}
            >
              <MenuItem value="dtm">DTM</MenuItem>
              <MenuItem value="dsm">DSM</MenuItem>
            </Select>
          </FormControl> */}
          <ButtonCustom
            label={t('common.uploadButton')}
            className="buttonStyleColoOutline"
            variant="contained"
            //handleClick={handleOpenModal}
            tooltip={t('common.comingSoon')}
          />
        </div>)
      })}

      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
                <ButtonCustom
                  label={t('common.backButton')}
                  startIcon={<ArrowBackIcon />}
                  className="backButton"
                  handleClick={handleStep(1)}
                  tooltip={t('common.backTooltip')}
                />
              <ButtonCustom
                label={t('common.continueButton')}
                className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
                variant="contained"
                handleClick={handleStep(3)}
                tooltip={t('common.continueTooltip', { extraText: "" })}
                disabled={isNextStepBlocked}
              />
        </div>
      </Box>
    </div>
  );

}

export default Step3