import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";




function CustomSnackbar(props: any) {

    const {
        snackbarProps,
        openSnackbar,
        setOpenSnackbar
    } = props

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false)
    }

    return (
        <Snackbar
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleCloseSnackbar}
                severity={snackbarProps.severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbarProps.message}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnackbar
